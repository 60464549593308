import {
  DEFAULT_ERROR_MESSAGE,
  InternalServerErrorStatusMessage,
  MISSING_TOKENS_ERROR_MESSAGE,
  MissingTokensStatusMessage,
  StatusErrors,
} from '../constants'

// @note Use this custom api error class to handle all the errors from the request
export class ApiError extends Error {
  constructor(
    public statusMessage: string = InternalServerErrorStatusMessage,
    public errors: string[],
    public statusCode: number = 500,
    public errorMessage: string = DEFAULT_ERROR_MESSAGE
  ) {
    super(errorMessage)
    this.errors = errors
    this.name = 'ApiError'
  }
}

export class MissingTokensError extends Error {
  public statusCode: number = 401

  constructor(
    public statusMessage: string = MissingTokensStatusMessage,
    public errorMessage = MISSING_TOKENS_ERROR_MESSAGE
  ) {
    super(statusMessage)
    this.name = 'LogoutError'
  }
}

export const isErrorToLogout = (error: ApiError) =>
  [StatusErrors.Unauthorized, StatusErrors.Forbidden].some(
    (status) =>
      status.text === error.statusMessage || status.code === error.statusCode
  )
