import { getCookie } from 'cookies-next'
import {
  differenceInMinutes,
  format,
  formatDistanceToNow,
  Locale,
  parseISO,
} from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { jwtVerify } from 'jose'
import { jwtDecode } from 'jwt-decode'
import validator from 'validator'
import {
  BrowserModel,
  DEFAULT_DEVICE_INFO,
  DEVICE_KEY,
  DeviceType,
  OperativeSystem,
  UserAgent,
} from '../constants'
import { TokenPayload } from '../types'
import { cubanPhoneCodes } from '../constants/cuban-phones-prefix'

export const verifyToken = async (accessToken: string) => {
  const secretKey = process.env.NEXT_PUBLIC_SECRET_KEY
  const encodedSecret = new TextEncoder().encode(secretKey)
  await jwtVerify(accessToken, encodedSecret)
}

export const getOS = (agent: string | null) => {
  const osUserAgent = Object.values(UserAgent).find(
    (osUserAgentValue) => agent && agent.includes(osUserAgentValue)
  )

  const os = Object.values(OperativeSystem).find(
    (operativeSystemValue) =>
      (osUserAgent === UserAgent.iPhone &&
        operativeSystemValue === OperativeSystem.IOS) ||
      (osUserAgent && osUserAgent.includes(operativeSystemValue))
  )

  return os ?? DEFAULT_DEVICE_INFO
}

export const getDeviceType = (agent: string | null) => {
  const predicate = (value: (typeof UserAgent)[keyof typeof UserAgent]) =>
    agent && agent.includes(value)

  const isPcDevice = [
    UserAgent.Linux_x86_64,
    UserAgent.Linux_i686,
    UserAgent.MacOS,
    UserAgent.Windows,
  ].some(predicate)

  const isTabletDevice = [UserAgent.Tablet, UserAgent.iPad].some(predicate)

  const isMobileDevice = [UserAgent.Android, UserAgent.iPhone].some(predicate)

  if (isPcDevice) {
    return DeviceType.PC
  } else if (isTabletDevice) {
    return DeviceType.Tablet
  } else if (isMobileDevice) {
    return DeviceType.Mobile
  }

  return DEFAULT_DEVICE_INFO
}

export const getBrowser = (agent: string | null) => {
  const browser = Object.values(BrowserModel).find(
    (model) => agent && agent.includes(model)
  )
  return browser ?? DEFAULT_DEVICE_INFO
}

export const getDeviceKey = () => {
  const maxmindId = getCookie(DEVICE_KEY)

  return maxmindId || ''
}

export const padWithZero = (num: number) => {
  return String(num).padStart(2, '0')
}

export const getOtpTime = (isPhone: boolean) => {
  const phoneTimer = process.env.NEXT_PUBLIC_PHONE_OTP_TIME ?? '60'
  const emailTimer = process.env.NEXT_PUBLIC_EMAIL_OTP_TIME ?? '300'
  const optTime = isPhone ? phoneTimer : emailTimer

  return parseFloat(optTime)
}

export interface DeviceInfoResponse {
  appVersion: string
  deviceKey: string
  model: string
  operatingSystem: string
  type: string
}

export const getDeviceInfo = ({
  userAgent,
  version,
}: {
  userAgent: string | null
  version: string
}): DeviceInfoResponse => {
  return {
    appVersion: version,
    deviceKey: getDeviceKey(),
    model: getBrowser(userAgent),
    operatingSystem: getOS(userAgent),
    type: getDeviceType(userAgent),
  }
}

export const isValidPhoneNumber = (phoneNumber: string) => {
  return validator.isMobilePhone(phoneNumber, 'any', { strictMode: true })
}

export const createCubanPhoneRegex = () => {
  const regexParts = cubanPhoneCodes.map(
    (code) => `(${code}\\d{${8 - code.toString().length}})`
  )
  return new RegExp(`^\\+53(${regexParts.join('|')})$`)
}

export const isValidCubanPhoneNumber = ({
  phoneNumber,
  allowHomePhone,
}: {
  phoneNumber: string
  allowHomePhone?: boolean
}) => {
  const mobilePhoneRegex = /^\+535[0-9]{7}$|^\+5363[0-9]{6}$/

  if (!allowHomePhone) return mobilePhoneRegex.test(phoneNumber)

  return (
    mobilePhoneRegex.test(phoneNumber) ||
    createCubanPhoneRegex().test(phoneNumber)
  )
}

export const isValidEmail = (email: string) => validator.isEmail(email)

export const isSuccess = (status: number) => {
  return status >= 200 && status <= 300
}

export const getCookieExpireTime = (expire: number) => new Date(expire * 1000)

export const decodeTokens = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string
  refreshToken: string
}) => {
  const accessTokenPayload = jwtDecode<TokenPayload>(accessToken)
  const refreshTokenPayload = jwtDecode<TokenPayload>(refreshToken)

  return {
    accessTokenPayload,
    refreshTokenPayload,
  }
}

export const decodeAccessToken = (accessToken: string) => {
  return jwtDecode<TokenPayload>(accessToken)
}

export const isEmpty = (value: unknown): value is undefined | null | '' | {} =>
  value === undefined ||
  value === null ||
  value === '' ||
  (typeof value === 'object' && !Object.keys(value).length)

export const parseDateToISO = (date: string | number | Date): Date => {
  const strDate = new Date(date).toISOString().split('T')[0]
  const isoDate = parseISO(strDate)
  return isoDate
}

export const formatDateToLocalString = (date: string, locale = 'es') => {
  const isoDate = parseDateToISO(date)
  const dateLocale = locale === 'en' ? enUS : es
  return format(isoDate, 'EEE, d MMM yyyy', { locale: dateLocale })
}

export const formatDateDDMMYYYY = (date: Date | string | number) => {
  const isoDate = parseDateToISO(date)
  const formattedDate = format(isoDate, 'dd/MM/yyyy')
  return formattedDate
}

export const formatNotificationDateToNow = ({
  createdAt,
  locale,
}: {
  createdAt: string
  locale: Locale
}) => {
  const date = new Date(createdAt)
  const formattedDate = formatDistanceToNow(date, { addSuffix: false, locale })
  return formattedDate
}

type DynamicRoute = `${string}/[${string}]/${string}`

type ExtractParam<Path, NextPart> = Path extends `[${infer Param}]`
  ? Record<Param, string | number> & NextPart
  : NextPart

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, {}>

export const replaceParamsFromRoute = <TRoute extends DynamicRoute>(
  route: TRoute,
  params: ExtractParams<TRoute>
) => {
  const newRoute = route
    .split('/')
    .map((part) => {
      if (part.startsWith('[') && part.endsWith(']')) {
        const paramKey = part.replace('[', '').replace(']', '')
        return params[paramKey as keyof typeof params]
      }
      return part
    })
    .join('/')
  return encodeURI(newRoute)
}

export const addCubanPrefix = (value?: string | null) => {
  if (!value) return ''

  return value.startsWith('+53') ? value : `+53${value}`
}

export const addUsaPrefix = (value?: string | null) => {
  if (!value) return ''
  return value.startsWith('+1') ? value : `+1${value}`
}

export const clearPhonePrefix = ({
  countryCode,
  value,
}: {
  countryCode: 'cu' | 'us'
  value?: string | null
}) => {
  if (!value) return ''

  if (countryCode === 'cu') return value.replace('+53', '')
  return value.replace('+1', '')
}

export const clearFormattedPhone = (value: string) =>
  value.replaceAll(' ', '').replace(/[()\\-]/g, '')

export const hasDifferentValues = <TObject extends Record<string, unknown>>(
  mainObject: TObject,
  objectToCompare: TObject
): boolean => JSON.stringify(mainObject) !== JSON.stringify(objectToCompare)

export const convertDateToISO = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const milliseconds = date.getMilliseconds()

  const dateUTC = new Date(
    Date.UTC(year, month, day, hour, minutes, seconds, milliseconds)
  )

  return dateUTC.toISOString()
}

export const differenceInHoursAndMinutes = (
  departure: string,
  arrival: string
) => {
  const departureDate = parseISO(departure)
  const arrivalDate = parseISO(arrival)

  const totalMinutesDifference = differenceInMinutes(arrivalDate, departureDate)

  const hours = Math.floor(totalMinutesDifference / 60)
  const minutes = totalMinutesDifference % 60

  return `${hours}h ${minutes}m`
}
