import { apiBaseUrl } from '../config'
import {
  AbortErrorStatusMessage,
  AbortErrorTimeout,
  InternalServerErrorStatusMessage,
} from '../constants'
import { BaseApiResponse } from '../types'
import { isSuccess } from '../utils'

const fetchHandler = (baseUrl: string) => {
  const request = async <TBody extends object>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    url: string,
    body: TBody | null,
    { headers, ...config }: Omit<RequestInit, 'body' | 'method'>
  ): Promise<Response> => {
    const _url = `${baseUrl}${url}`

    let _body

    const _headers = new Headers({
      'Content-Type': 'application/json',
      ...headers,
    })

    const isMethodValid = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)
    if (isMethodValid && body) {
      const isBodyFormData = body instanceof FormData

      if (isBodyFormData) {
        _body = body
        _headers.delete('Content-Type')
      } else {
        _body = JSON.stringify(body)
      }
    }

    // TODO: Handle status code 500. Fetch api only throw an error when the status code is 500, so in the catch block we need to handle the error for 500 and the error for abort signal.
    try {
      const response = await fetch(_url, {
        ...config,
        method,
        body: _body,
        headers: _headers,
        signal: AbortSignal.timeout(AbortErrorTimeout),
      })
      if (response.status >= 500) {
        const jsonResponse: BaseApiResponse<null> = {
          data: null,
          errors: [InternalServerErrorStatusMessage],
          message: InternalServerErrorStatusMessage,
        }
        return {
          status: 0,
          ok: false,
          type: 'error',
          statusText: 'InternalServerError',
          json: () => Promise.resolve(jsonResponse),
        } as Response
      }

      return response
    } catch (error) {
      const jsonResponse: BaseApiResponse<null> = {
        data: null,
        errors: [AbortErrorStatusMessage],
        message: AbortErrorStatusMessage,
      }
      return {
        status: 0,
        ok: false,
        type: 'error',
        statusText: 'AbortError',
        json: () => Promise.resolve(jsonResponse),
      } as Response
    }
  }

  return {
    get(url: string, config: Omit<RequestInit, 'body' | 'method'> = {}) {
      return request('GET', url, null, config)
    },
    post<TBody extends object>(
      url: string,
      body: TBody,
      config: Omit<RequestInit, 'body' | 'method'> = {}
    ) {
      return request<TBody>('POST', url, body, config)
    },
    put<TBody extends object>(
      url: string,
      body: TBody,
      config: Omit<RequestInit, 'body' | 'method'> = {}
    ) {
      return request<TBody>('PUT', url, body, config)
    },
    patch<TBody extends object>(
      url: string,
      body: TBody,
      config: Omit<RequestInit, 'body' | 'method'> = {}
    ) {
      return request<TBody>('PATCH', url, body, config)
    },
    delete<TBody extends object>(
      url: string,
      config: Omit<RequestInit, 'body' | 'method'> = {},
      body: TBody | null = null
    ) {
      return request('DELETE', url, body, config)
    },
  }
}

export const request = fetchHandler(apiBaseUrl as string)

// TODO: Can we use this adapter to handle the response from the requests
export const responseAdapter = <TData>({
  data,
  status,
}: {
  data: TData
  status: number
}) => {
  return {
    ...data,
    statusCode: status,
    success: isSuccess(status),
  }
}
