export const AbortErrorStatusMessage = 'REQUEST_ABORTED'
export const AbortErrorTimeout = 30000

export const InternalServerErrorStatusMessage = 'INTERNAL_SERVER_ERROR'
export const MiddlewareErrorMessage = 'MIDDLEWARE_ERROR'
export const MissingTokensStatusMessage = 'MISSING_TOKENS'

export const DEFAULT_ERROR_MESSAGE = 'Algo salió mal'
export const MISSING_TOKENS_ERROR_MESSAGE = 'Missing tokens'
export const ABORT_ERROR_MESSAGE = 'Error de conexión'

export const StatusErrors = {
  Unauthorized: {
    text: 'Unauthorized',
    code: 401,
  },
  Forbidden: {
    text: 'Forbidden resource',
    code: 403,
  },
  NotFound: {
    text: 'Not Found',
    code: 404,
  },
}
