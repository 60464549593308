import { z } from 'zod'

export const getIETFEmailSchema = ({
  invalidMessage,
  requiredMessage,
}: {
  requiredMessage?: string
  invalidMessage: string
}) =>
  z
    .string({
      required_error: requiredMessage,
      invalid_type_error: requiredMessage,
    })
    .refine(
      (value) => {
        const usernameLength = value.split('@')[0].length
        return usernameLength >= 1 && usernameLength <= 64
      },
      {
        message: invalidMessage,
      }
    )

export const requiredDate = ({
  requiredMessage,
}: {
  requiredMessage: string
}) =>
  z.date({
    coerce: true,
    errorMap: () => ({ message: requiredMessage }),
  })
