export const DEFAULT_DEVICE_INFO = 'Other'

export const UserAgent = {
  MacOS: 'Mac OS',
  Windows: 'Windows',
  Linux_i686: 'Linux i686',
  Linux_x86_64: 'Linux x86_64',
  Android: 'Android',
  iPhone: 'iPhone',
  iPad: 'iPad',
  Tablet: 'Tablet',
} as const

export const BrowserModel = {
  Firefox: 'Firefox',
  Chrome: 'Chrome',
  Opera: 'Opera',
  Edge: 'Edge',
  Safari: 'Safari',
} as const

export const OperativeSystem = {
  MacOS: 'Mac OS',
  Windows: 'Windows',
  Linux: 'Linux',
  Android: 'Android',
  IOS: 'IOS',
} as const

export const enum DeviceType {
  PC = 'PC',
  Tablet = 'Navegador Tablet',
  Mobile = 'Navegador móvil',
}
