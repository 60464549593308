// @ts-nocheck
import { setCookie } from 'cookies-next'
import { DEVICE_KEY } from '../constants'

export async function setDeviceKey() {
  const fingerprint: Record<string, unknown> = {}
  fingerprint.userAgent = navigator.userAgent
  fingerprint.language = navigator.language
  fingerprint.cookieEnabled = navigator.cookieEnabled
  fingerprint.hardwareConcurrency = navigator.hardwareConcurrency
  fingerprint.deviceMemory = navigator.deviceMemory || null

  fingerprint.maxTouchPoints = navigator.maxTouchPoints
  fingerprint.connection = navigator.connection
    ? navigator.connection.effectiveType
    : null
  fingerprint.screenWidth = screen.width
  fingerprint.screenHeight = screen.height
  fingerprint.screenAvailWidth = screen.availWidth
  fingerprint.screenAvailHeight = screen.availHeight
  fingerprint.screenColorDepth = screen.colorDepth
  fingerprint.screenPixelDepth = screen.pixelDepth
  fingerprint.windowInnerWidth = window.innerWidth
  fingerprint.windowInnerHeight = window.innerHeight
  fingerprint.windowOuterWidth = window.outerWidth
  fingerprint.windowOuterHeight = window.outerHeight
  fingerprint.pixelRatio = window.devicePixelRatio
  fingerprint.darkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const webglCanvas = document.createElement('canvas')
  const gl =
    webglCanvas.getContext('webgl') ||
    webglCanvas.getContext('experimental-webgl')

  if (gl) {
    fingerprint.gpuVendor = gl.getParameter(gl.VENDOR)
    fingerprint.gpuRenderer = gl.getParameter(gl.RENDERER)
    fingerprint.webglVersion = gl.getParameter(gl.VERSION)
    fingerprint.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE)
    fingerprint.maxViewportDims = gl.getParameter(gl.MAX_VIEWPORT_DIMS)
    fingerprint.supportedExtensions = gl.getSupportedExtensions()
    fingerprint.shadingLanguageVersion = gl.getParameter(
      gl.SHADING_LANGUAGE_VERSION
    )
  }
  const canvas2D = document.createElement('canvas')
  const ctx = canvas2D.getContext('2d')
  if (ctx) {
    ctx.fillStyle = 'rgb(100,150,200)'
    ctx.beginPath()
    ctx.roundRect(30, 30, 160, 120, 20)
    ctx.fill()
    ctx.stroke()
    ctx.closePath()
    ctx.fillStyle = 'rgb(255,200,0)'
    ctx.beginPath()
    ctx.arc(80, 80, 60, 0, Math.PI * 2, true)
    ctx.fill()
    ctx.stroke()
    ctx.closePath()
    const txt = 'こんПриمرحبا!你안녕σας'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = 'rgb(0,0,255)'
    ctx.rotate(-0.05)
    ctx.fillText(txt, 10, 30)
    ctx.fillStyle = 'rgb(0,255,0)'
    ctx.shadowBlur = 12
    ctx.shadowColor = 'blue'
    ctx.fillRect(30, 40, 120, 10)
    fingerprint.canvasDataUrl = canvas2D.toDataURL()
    fingerprint.canvasWidth = canvas2D.width
    fingerprint.canvasHeight = canvas2D.height
  }
  const encoder = new TextEncoder()
  const data = encoder.encode(JSON.stringify(fingerprint))
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  setCookie(DEVICE_KEY, hashHex, {
    path: '/',
    secure: true,
    sameSite: 'none',
    domain: window.location.hostname,
  })
}
