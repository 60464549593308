export const SERVICE_TYPE = {
  REMITTANCE: { key: 1, value: 'REMITTANCE' },
  TOPUP: { key: 2, value: 'TOPUP' },
  SHOPPING: { key: 3, value: 'SHOPPING' },
  CONSULAR_SERVICES: { key: 4, value: 'CONSULAR_SERVICES' },
  CAR: { key: 5, value: 'CAR' },
  BALANCE: { key: 6, value: 'BALANCE' },
  CALL: { key: 7, value: 'CALL' },
  SMS: { key: 8, value: 'SMS' },
  STORE: { key: 9, value: 'STORE' },
  LOCKER: { key: 10, value: 'LOCKER' },
  FLIGHTS: { key: 11, value: 'FLIGHTS' },
  HOTEL: { key: 12, value: 'HOTEL' },
  PASSPORT: { key: 13, value: 'PASSPORT' },
  SHIPMENT: { key: 14, value: 'SHIPMENT' },
  PACKAGES: { key: 15, value: 'PACKAGES' },
} as const

export type SERVICE_TYPE =
  | keyof typeof SERVICE_TYPE
  | Omit<string, keyof typeof SERVICE_TYPE>

export type SERVICE_VALUE =
  (typeof SERVICE_TYPE)[keyof typeof SERVICE_TYPE]['value']
